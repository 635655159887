export const getCron = (frequency: string, time: string, dayOfWeek: string) => {
  let hour = '';
  let minute = '';
  let cron = '';
  switch (frequency) {
    case 'nightly':
      cron = '0 0 * * *';
      break;
    case 'weekly':
      cron = '0 0 * * 0';
      break;
    case 'monthly':
      cron = '0 0 1 * *';
      break;
    case 'hour':
      cron = '0 * * * *';
      break;
    case 'day':
      [hour, minute] = time.split(':');
      cron = `${minute} ${hour} * * *`;
      break;
    case 'week':
      [hour, minute] = time.split(':');
      cron = `${minute} ${hour} * * ${dayOfWeek}`;
      break;
    case 'month':
      [hour, minute] = time.split(':');
      cron = `${minute} ${hour} 1 * *`;
      break;
    default:
      cron = '0 0 * * *';
      break;
  }
  return cron;
};

export const getValueFromCron = (cron: string): string => {
  switch (cron) {
    // Addressing supported cases
    case '0 0 * * *': // daily
      return 'nightly';
    case '0 0 1 * *': // monthly
      return 'monthly';
    case '0 0 * * 0': // weekly
      return 'weekly';
    default:
      return 'nightly';
  }
};
