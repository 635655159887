





































































































































































































import axios from 'axios';
import { DataSvcSchema } from 'generated/data-svc/api';
import Component from 'vue-class-component';

import DataApiManager from '@/api/dataApiManager';
import { Contact, ReconciliationStatus, Transaction } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiModal from '@/components/ui/UiModal.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { RegisterQuery } from '@/queries/registerQuery';
import { isDefined } from '@/utils/guards';

import { baConfig } from '../../../config';
import { getDataSourceSchemaNameByDataSourceId } from './DataImportUtils';
import { FeedInterface } from './Feed.types';
import FeedCreate from './FeedCreate.vue';
import FeedEdit from './FeedEdit.vue';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiPagination,
    UiModal,
    UiDataTable,
    UiTooltip,
    UiFormLabel,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    FeedCreate,
    FeedEdit,
  },
})
export default class Feeds extends BaseVue {
  declare register?: any;

  public isCreatingFeed = false;
  public isEditingFeed = false;

  public pageLimit = '10';
  public open = false;
  public pageToken = 1;
  public currentFeedItem: FeedInterface | null = null;
  public isLoadingFeeds = false;
  public isLoadingDataSource = false;
  public isStartFeedLoading = false;
  public feeds: FeedInterface[] = [];
  public dataSources: any = [];
  public isLoadingSchemas = false;
  public schemas: DataSvcSchema[] = [];
  public reportsLength = 0;

  async mounted() {
    await this.loadRequiredData();
  }

  readonly dropdownValues = [
    {
      value: 'executeFeedPipeline',
      label: 'Execute Feed Pipeline',
    },
    {
      value: 'editFeed',
      label: 'Edit Feed',
    },
  ];

  onDropdownSelect(valueSelected: any, feedItem: any) {
    switch (valueSelected) {
      case 'executeFeedPipeline':
        this.handleExecuteFeedButton(feedItem);
        break;

      case 'editFeed':
        this.handleEditFeed(feedItem);
        break;
    }
  }

  handleExecuteFeedButton(feed: FeedInterface) {
    this.currentFeedItem = feed;
    this.open = true;
  }

  handleEditFeed(feed: FeedInterface) {
    this.currentFeedItem = feed;
    this.isEditingFeed = true;
  }

  async onFeedCreatedHandler() {
    this.isCreatingFeed = false;
    await this.loadRequiredData();
  }

  async onFeedEditHandler() {
    this.isEditingFeed = false;
    await this.loadRequiredData();
  }

  async confirmModalHandler() {
    this.open = false;
    if (this.currentFeedItem?.orgId && this.currentFeedItem?.feedId) {
      await this.executeFeedPipeline(
        this.currentFeedItem?.orgId,
        this.currentFeedItem?.schemaId === 'btc-wallet'
          ? 'bulk-btc-wallet-validation-workflow'
          : 'bulk-transaction-feed-validation-workflow',
        this.currentFeedItem?.feedId
      );
    }
    this.currentFeedItem = null;
  }

  closeModalHandler() {
    this.currentFeedItem = null;
    this.open = false;
  }

  standardizeFrequency(frequency: string): string {
    switch (frequency) {
      case '0 0 * * *':
        return 'Nightly';
      case '0 0 1 * *':
        return 'Monthly';
      case '0 0 * * 0':
        return 'Weekly';
      default:
        return frequency;
    }
  }

  async executeFeedPipeline(orgId: string, workflowDefinitionId: string, feedId: string) {
    const ds = DataApiManager.getInstance();
    const userObj = this.$store.state.user;
    this.isStartFeedLoading = true;
    const User = { name: userObj?.displayName ?? '', id: userObj?.id ?? '' };
    try {
      const resp = await ds.startFeed(this.orgId, workflowDefinitionId, feedId, { User }, { withCredentials: true });
      if (resp.status === 200) {
        this.showSuccessSnackbar(`Feed Triggered Succesfully, Execution ID: ${resp.data.executionId}`);
      } else {
        this.showErrorSnackbar('Unabled to Trigger Feed, Please try after some time');
      }
    } catch (err) {
      console.log(err);
      this.showErrorSnackbar('Unabled to Trigger Feed, Please try after some time');
    } finally {
      this.isStartFeedLoading = false;
    }
  }

  async onDataSourceArgChanged() {
    // Reset page token and keys before loading another data source
    this.pageToken = 1;
    await this.loadFeeds();
  }

  async loadRequiredData() {
    const loadDataSourceAndSchema = [this.loadSchemas(), this.loadDataSources()];
    await Promise.all(loadDataSourceAndSchema);
    await this.loadFeeds();
  }

  async loadDataSources() {
    this.isLoadingDataSource = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        this.dataSources = resp.data.items;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSource = false;
    }
  }

  getDataSourceName(dataSourceId: string): string {
    if (this.dataSources.length) {
      const item = this.dataSources.filter((item: { id: string }) => item.id === dataSourceId)[0];
      return item ? item?.name : dataSourceId;
    }
    return dataSourceId;
  }

  getDataSourceSchemaNameByDataSourceId(dataSourceId: string): string {
    return getDataSourceSchemaNameByDataSourceId(dataSourceId, this.dataSources, this.schemas);
  }

  async loadSchemas() {
    this.isLoadingSchemas = true;
    try {
      const ds = DataApiManager.getInstance();

      const [respSchemas, respCustomSchemas] = await Promise.all([
        ds.listSchemas(this.orgId, { withCredentials: true }),
        ds.listCustomSchemas(this.orgId, { withCredentials: true }),
      ]);
      if (respSchemas.status === 200 && respCustomSchemas.status === 200) {
        const allSchemas = [...respSchemas.data.items, ...respCustomSchemas.data.items];
        // Only show enabled styles
        this.schemas = allSchemas.filter((item) => item.status === 'Enabled');
      }
    } catch (e) {
      console.log('Problem loading schemas', e);
      this.showErrorSnackbar('Problem loading schemas');
    } finally {
      this.isLoadingSchemas = false;
    }
  }

  async loadFeeds() {
    this.isLoadingFeeds = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.getFeeds(this.orgId, this.pageToken, parseInt(this.pageLimit, 10), {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.feeds = resp.data.items.map((item) => {
          const dataSources = this.dataSources;
          return {
            orgId: item.OrgId,
            createdBy: JSON.parse(item.CreatedBy).name,
            lastModifiedBy: JSON.parse(item.LastModifiedBy).name,
            feedId: item.FeedId,
            createdAt: item.CreatedAt,
            lastModifiedAt: item.LastModifiedAt,
            schemaId: dataSources.filter((check: any) => check.id === item.DataSourceId)[0].schemaId,
            name: item.Name,
            dataSourceId: item.DataSourceId,
            status: item.Status,
            frequency: item.Frequency,
          };
        });
        this.reportsLength = resp.data.dataLength;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        console.log((e as Error).message);
        this.showErrorSnackbar('Something went wrong, Please try again later.');
      }
    } catch (err) {
      console.log((err as Error).message);
      this.showErrorSnackbar('Something went wrong, Please try again later.');
    } finally {
      this.isLoadingFeeds = false;
    }
  }

  readonly headers = [
    {
      id: 'icon',
      label: '',
      defaultVisibility: true,
    },
    {
      id: 'feedId',
      label: 'Feed Id',
      defaultVisibility: true,
    },
    {
      id: 'name',
      label: 'Feed Name',
      defaultVisibility: true,
    },
    {
      id: 'dataSourceId',
      label: 'Data Source Id',
      defaultVisibility: true,
    },
    {
      id: 'dataSourceName',
      label: 'Data Source Name',
      defaultVisibility: true,
    },
    {
      id: 'dataType',
      label: 'File Type',
      defaultVisibility: true,
    },
    {
      id: 'frequency',
      label: 'Frequency',
      defaultVisibility: true,
    },
    {
      id: 'createdBy',
      label: 'Created By',
      defaultVisibility: true,
    },
    {
      id: 'createdAt',
      label: 'Created At',
      defaultVisibility: true,
    },
    {
      id: 'lastModifiedBy',
      label: 'Last Edit By',
      defaultVisibility: false,
    },
    {
      id: 'lastModifiedAt',
      label: 'Last Edit At',
      defaultVisibility: false,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      defaultVisibility: true,
    },
  ];

  public allSelected = false;
  public isLoading = 0;
  public showFilters = false;
  public skipQuery = true;

  public endDate = new Date().toISOString().substring(0, 10);

  public vars = {
    filter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletId: 'All',
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public get displayRegister() {
    console.log(this.register);
    return this.isLoading ? [] : (this.register?.items ?? []).filter(isDefined);
  }

  public async loadRegister() {
    if (this.$store.state.currentOrg && this.walletId !== 'select') {
      this.isLoading = 1;
      let currencyId;
      let hasError = false;
      if (this.ticker && this.ticker !== null) {
        const assetsApiUrl = `${baConfig.addressSvcUrl}/symbols/${this.ticker}`;
        const resp = await axios.get(assetsApiUrl);
        if (resp === undefined || resp.status !== 200) {
          hasError = true;
          this.showErrorSnackbar(`Invalid ticker ${this.ticker}`);
        } else {
          currencyId = `COIN.${resp.data.coinId}`;
        }
      }
      if (!hasError) {
        const res = await this.$apollo.query({
          query: RegisterQuery,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            filter: {
              walletId: this.walletId,
              endDate: this.endDate,
              currencyId,
            },
          },
        });
        this.register = res.data.register;
      }

      this.isLoading = 0;
    }
  }

  public showMenuOn: Transaction | null = null;
  public expandedTxn: Transaction | null = null;
  public showCreateManualTxnModal = false;
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: Transaction | null = null;
  public deleteDialog = false;
  public isSoftLoading = 0;
  public walletId = 'select';
  public ticker: string | null = null;

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public async refresh() {
    await Promise.all([this.$apollo.queries.register.refetch()]);

    this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
    this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
  }

  toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }
}
