
















































































































import { v4 as uuid } from 'uuid';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { getEmptyStringProps } from '@/utils/stringUtils';

import { DataSvcFeedRequest, DataSvcSchema } from '../../../generated/data-svc';
import { getCron } from './FeedCreate.utils';
@Component({
  components: {
    UiButton,
    UiDropdown,
    UiFormLabel,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
})
export default class FeedCreate extends BaseVue {
  public isLoading = false;
  public isLoadingDataSources = false;

  @Prop({ default: null })
  public dataSourceId!: string;

  @Prop({ default: false })
  public showExistingDataSourceCheckbox!: boolean;

  @Prop({ default: null })
  public schemaId!: string;

  public dataSource!: { id: string; name: string } | null;

  public isValidInput = false;
  public status = true;
  public min = 0;
  public max = 6;
  public cron = '0 0 * * *'; // Default cron at midnight daily

  @Prop({ default: true })
  public readonly isCreateButtonVisible!: boolean;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('oncreateFeedButtonClick')
  async oncreateFeedButtonClick() {
    const response = await this.createFeed();
    return response;
  }

  generateName(prefix = 'DataSource') {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${prefix}-${year}-${month}-${day}-${hour}-${minute}`;
  }

  public onDataChange() {
    this.paramsArray =
      this.schemas.filter((schema) => schema.id === this.newDataSourceSchema?.id)[0].params?.split(',') || [];
  }

  async loadDataSources() {
    this.isLoadingDataSources = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        const loaded = resp.data.items.filter((m) => m.type === 'Loaded');
        this.loadedDataSources = [];
        for (const l of loaded) {
          this.loadedDataSources.push({
            name: l.name,
            id: l.id,
          });
        }
      } else {
        const e = new Error('Failed to load data sources. Status Code:' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSources = false;
    }
  }

  public isCreatingFeed = false;
  public isCreatingDataSource = false;
  public isExistingDataSource = false;
  public loadedDataSources: any = [];
  public createdDataSourceId = '';

  public newDataSourceName = this.generateName();
  public newFeedName = this.generateName('Feed');
  public newDataSourceSchema: { id: string; name: string } | null = null;
  public accountId = '';

  public newFrequency: { id: string; name: string; value: string } = {
    id: '',
    name: '',
    value: '',
  };

  public dayOfWeek = '';
  public time = '';
  public schemas: DataSvcSchema[] = [];
  public paramsArray: string[] = [];
  public paramValues: Record<string, string> = {};

  public validateNameLength(name: string) {
    return name.length >= 3;
  }

  public get frequencyList() {
    const options = [
      {
        id: 'nightly',
        name: 'Nightly',
      },
      {
        id: 'weekly',
        name: 'Weekly',
      },
      {
        id: 'monthly',
        name: 'Monthly',
      },
      /*  To be added post cron
      {
        id: 'hour',
        name: 'Hour',
      },
      {
        id: 'day',
        name: 'Day',
      },
      {
        id: 'week',
        name: 'Week',
      },
      {
        id: 'month',
        name: 'Month',
      } */
    ];
    return options;
  }

  public get schemaList() {
    return this.schemas
      .filter((s) => this.allowSchemaBeta || !s.isSchemaBeta)
      .map((s) => ({ name: s.isSchemaBeta ? `${s.name} (Beta)` : s.name, id: s.id }));
  }

  public get allowSchemaBeta() {
    return this.checkFeatureFlag('schema-beta', this.$store.getters.features);
  }

  public validate() {
    if (
      this.validateNameLength(this.newFeedName) &&
      this.validateNameLength(this.newDataSourceName) &&
      this.newDataSourceSchema &&
      this.cron
    ) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }

  async mounted() {
    const loadDataCalls = [this.loadSchemas(), this.loadDataSources()];
    try {
      await Promise.all(loadDataCalls);
      if (this.dataSourceId) {
        this.dataSource = this.loadedDataSources.filter(
          (dataSource: { id: string }) => dataSource.id === this.dataSourceId
        )[0];
        this.newDataSourceName = this.dataSource?.name ?? '';
      }
      if (this.schemaId) {
        this.newDataSourceSchema = this.schemas.filter((schema) => schema.id === this.schemaId)[0];
      }
    } catch (err) {
      console.log(err);
    }
  }

  async loadSchemas() {
    this.isLoading = true;
    try {
      const ds = DataApiManager.getInstance();

      const [respSchemas, respCustomSchemas] = await Promise.all([
        ds.listSchemas(this.orgId, { withCredentials: true }),
        ds.listCustomSchemas(this.orgId, { withCredentials: true }),
      ]);
      if (respSchemas.status === 200 && respCustomSchemas.status === 200) {
        const allSchemas = [...respSchemas.data.items, ...respCustomSchemas.data.items];
        // Only show enabled styles
        this.schemas = allSchemas.filter((item) => item.status === 'Enabled');
      }
    } catch (e) {
      console.log('Problem loading schemas', e);
      this.showErrorSnackbar('Problem loading schemas');
    } finally {
      this.isLoading = false;
    }
  }

  public async createDataSource() {
    if (this.newDataSourceSchema && this.newDataSourceSchema) {
      this.isCreatingDataSource = true;
      try {
        const ds = DataApiManager.getInstance();
        const emptyParamProperties = getEmptyStringProps(this.paramValues);
        if (this.newDataSourceSchema.id !== 'transaction' && emptyParamProperties.length !== 0) {
          this.showErrorSnackbar(
            `Parameters required for creating data source missing - ${emptyParamProperties.join(',')}`
          );
          throw new Error('Parameters required for creating data source missing');
        }
        const res = await ds.createDataSources(
          this.orgId,
          { name: this.newDataSourceName, schemaId: this.newDataSourceSchema.id, params: this.paramValues },
          { withCredentials: true }
        );
        if (res.status === 200) {
          this.$emit('onCreated');
          this.createdDataSourceId = res.data.id;
        } else {
          this.showErrorSnackbar('Error creating data source');
        }
        return res;
      } catch (e) {
        console.log('Error creating data source: ', e);
      } finally {
        this.isCreatingDataSource = false;
      }
    }
  }

  public async createFeed() {
    this.validate();
    await this.createDataSource();
    const schemaId = this.newDataSourceSchema?.id ?? '';
    if (this.isValidInput && this.createdDataSourceId && schemaId) {
      this.isCreatingFeed = true;
      const userObj = this.$store.state.user;
      const payload: DataSvcFeedRequest = {
        FeedId: uuid(),
        Name: this.newFeedName,
        Frequency: getCron(this.newFrequency.id, this.time, this.dayOfWeek),
        OrgId: this.orgId,
        Status: this.status ? 'Enabled' : 'Disabled',
        User: { name: userObj?.displayName ?? '', id: userObj?.id ?? '' },
        DataSourceId: this.createdDataSourceId,
      };
      try {
        const ds = DataApiManager.getInstance();
        const res = await ds.feed(this.orgId, payload, { withCredentials: true });
        if (res.status === 200) {
          this.showSuccessSnackbar('Feed Created Successfully');
          this.$emit('onCreated');
        } else {
          this.showErrorSnackbar('Error creating Feed');
        }
        return res;
      } catch (e) {
        console.log('Error creating Feed: ', e);
      } finally {
        this.isCreatingFeed = false;
      }
    }
  }
}
