





















































































import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';

import { DataSvcFeedRequest, DataSvcSchema } from '../../../generated/data-svc';
import { getDataSourceName } from './DataImportUtils';
import type { FeedInterface } from './Feed.types';
import { getCron, getValueFromCron } from './FeedCreate.utils';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiFormLabel,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
})
export default class FeedEdit extends BaseVue {
  @Prop()
  public feedItem!: FeedInterface;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('oneditFeedButtonClick')
  async oneditFeedButtonClick() {
    const response = await this.editFeed();
    return response;
  }

  public isLoadingSchemas = false;
  public isLoadingDataSources = false;
  public isLoadingDataSourceItem = false;
  public isValidInput = false;
  public status = true;
  public min = 0;
  public max = 6;
  public cron = '0 0 * * *'; // Default cron at midnight daily
  public isEditingFeed = false;
  public isEditingDataSource = false;
  public loadedDataSources: any = [];
  public editedDataSourceId = '';
  public newDataSourceName = 'loading..';
  public initialDataSourceName = '';
  public newFeedName = 'loading..';
  public newDataSourceSchema: { id: string; name: string } | null = null;
  public dayOfWeek = '';
  public time = '';
  public schemas: DataSvcSchema[] = [];
  public newFrequency: { id: string; name: string } = {
    id: '',
    name: '',
  };

  public get frequencyList() {
    const options = [
      {
        id: 'nightly',
        name: 'Nightly',
      },
      {
        id: 'weekly',
        name: 'Weekly',
      },
      {
        id: 'monthly',
        name: 'Monthly',
      },
      /*  To be added post cron
      {
        id: 'hour',
        name: 'Hour',
      },
      {
        id: 'day',
        name: 'Day',
      },
      {
        id: 'week',
        name: 'Week',
      },
      {
        id: 'month',
        name: 'Month',
      } */
    ];
    return options;
  }

  public get schemaList() {
    return this.schemas
      .filter((s) => this.allowSchemaBeta || !s.isSchemaBeta)
      .map((s) => ({ name: s.isSchemaBeta ? `${s.name} (Beta)` : s.name, id: s.id }));
  }

  public get allowSchemaBeta() {
    return this.checkFeatureFlag('schema-beta', this.$store.getters.features);
  }

  async mounted() {
    const loadDataCalls = [this.loadSchemas(), this.loadDataSources()];
    try {
      await Promise.all(loadDataCalls);
      this.newFeedName = this.feedItem.name;
      this.initialDataSourceName = getDataSourceName(this.feedItem.dataSourceId, this.loadedDataSources);
      this.newDataSourceName = this.initialDataSourceName;
      const dataSource: any = await this.getDataSourceById(this.feedItem.dataSourceId);
      const initialValue = this.schemas.filter((schema) => schema.id === dataSource?.Data.schemaId)[0];
      this.status = this.feedItem.status === 'Enabled';
      this.newDataSourceSchema = { name: initialValue.name, id: initialValue.id };
      this.newFrequency = this.frequencyList.filter((item) => item.id === getValueFromCron(this.feedItem.frequency))[0];
      this.validate();
    } catch (err) {
      console.log(err);
      this.showErrorSnackbar('Failed to Load Data for given feed');
    }
  }

  async getDataSourceById(dataSourceId: string) {
    this.isLoadingDataSourceItem = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.getDataSourceById(this.orgId, dataSourceId, { withCredentials: true });
      if (resp.status === 200) {
        return resp.data;
      }
    } catch (err) {
      this.showErrorSnackbar('Failed to load data source item');
    } finally {
      this.isLoadingDataSources = false;
    }
  }

  async loadSchemas() {
    this.isLoadingSchemas = true;
    try {
      const ds = DataApiManager.getInstance();

      const [respSchemas, respCustomSchemas] = await Promise.all([
        ds.listSchemas(this.orgId, { withCredentials: true }),
        ds.listCustomSchemas(this.orgId, { withCredentials: true }),
      ]);
      if (respSchemas.status === 200 && respCustomSchemas.status === 200) {
        const allSchemas = [...respSchemas.data.items, ...respCustomSchemas.data.items];
        // Only show enabled styles
        this.schemas = allSchemas.filter((item) => item.status === 'Enabled');
      }
    } catch (e) {
      console.log('Problem loading schemas', e);
      this.showErrorSnackbar('Problem loading schemas');
    } finally {
      this.isLoadingSchemas = false;
    }
  }

  async loadDataSources() {
    this.isLoadingDataSources = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        const loaded = resp.data.items.filter((m) => m.type === 'Loaded');
        this.loadedDataSources = [];
        for (const l of loaded) {
          this.loadedDataSources.push({
            name: l.name,
            id: l.id,
          });
        }
      } else {
        const e = new Error('Failed to load data sources. Status Code:' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSources = false;
    }
  }

  public validateNameLength(name: string) {
    return name.length >= 3;
  }

  public validate() {
    if (
      this.validateNameLength(this.newFeedName) &&
      this.validateNameLength(this.newDataSourceName) &&
      this.newDataSourceSchema &&
      this.newFrequency
    ) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }

  public async editDataSource() {
    if (this.newDataSourceSchema && this.newDataSourceSchema) {
      this.isEditingDataSource = true;
      try {
        const ds = DataApiManager.getInstance();
        const res = await ds.updateDataSource(
          this.orgId,
          this.feedItem.dataSourceId,
          { name: this.newDataSourceName, schemaId: this.newDataSourceSchema.id },
          { withCredentials: true }
        );
        if (res.status === 200) {
          this.$emit('onEdited');
          this.editedDataSourceId = this.feedItem.dataSourceId;
        } else {
          this.showErrorSnackbar('Error updating data source');
        }
        return res;
      } catch (e) {
        console.log('Error updating data source: ', e);
        this.showErrorSnackbar('Error updating data source');
      } finally {
        this.isEditingDataSource = false;
      }
    }
  }

  public async editFeed() {
    this.validate();
    if (this.initialDataSourceName !== this.newDataSourceName) {
      await this.editDataSource();
    }
    const schemaId = this.newDataSourceSchema?.id ?? '';
    if (this.isValidInput && schemaId) {
      this.isEditingFeed = true;
      const userObj = this.$store.state.user;
      const payload: DataSvcFeedRequest = {
        FeedId: this.feedItem.feedId,
        Name: this.newFeedName,
        Frequency: getCron(this.newFrequency.id, this.time, this.dayOfWeek),
        OrgId: this.orgId,
        Status: this.status ? 'Enabled' : 'Disabled',
        User: { name: userObj?.displayName ?? '', id: userObj?.id ?? '' },
        DataSourceId: this.feedItem.dataSourceId,
      };
      try {
        const ds = DataApiManager.getInstance();
        const res = await ds.feed(this.orgId, payload, { withCredentials: true });
        if (res.status === 200) {
          this.showSuccessSnackbar('Feed Updated Successfully');
          this.$emit('onEdited');
        } else {
          this.showErrorSnackbar('Error updating Feed');
        }
        return res;
      } catch (e) {
        console.log('Error updating Feed: ', e);
      } finally {
        this.isEditingFeed = false;
      }
    }
  }
}
