


































































































































































import {
  DataCoreSvcFeedhdlCreateInput,
  DataCoreSvcFeedsCreateAbbsTransactionFeedTypeEnum,
  DataCoreSvcFeedsCreateFeedUnion,
  DataCoreSvcFeedsCreateTransactionFeedTypeEnum,
} from 'generated/data-core-svc';
import { v4 as uuid } from 'uuid';
import Component from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';

import DataApiManager from '@/api/dataCoreApiManager';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiCheckbox from '@/components/ui/UiCheckbox.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { getEmptyStringProps } from '@/utils/stringUtils';

import { DataSvcFeedRequest, DataSvcSchema } from '../../../generated/data-svc';
import { WalletsQuery } from '../../queries/transactionsPageQuery';
import { getCron } from './FeedCreate.utils';
@Component({
  components: {
    UiButton,
    UiDropdown,
    UiFormLabel,
    UiCheckbox,
    UiTooltip,
    UiLoading,
    UiSelect2,
    UiTextEdit,
    UiDataTable,
  },
  apollo: {
    wallets: {
      query: WalletsQuery,
      variables() {
        if (this.$store.state.currentOrg) {
          return {
            orgId: this.$store.state.currentOrg.id,
          };
        } else {
          return false;
        }
      },
      loadingKey: 'isLoadingWallets',
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'no-cache',
    },
  },
})
export default class FeedCreate extends BaseVue {
  public isLoading = false;
  public isLoadingDataSources = false;
  public isLoadingWallets = 0;

  @Prop({ default: null })
  public dataSourceId!: string;

  @Prop({ default: false })
  public showExistingDataSourceCheckbox!: boolean;

  @Prop({ default: null })
  public schemaId!: string;

  public dataSource!: { id: string; name: string } | null;
  public wallets: any = [];
  public feedType = 'transaction';

  public isValidInput = false;
  public status = true;
  public min = 0;
  public max = 6;
  public cron = '0 0 * * *'; // Default cron at midnight daily

  @Prop({ default: true })
  public readonly isCreateButtonVisible!: boolean;

  @Prop({ default: false })
  public readonly disabled!: boolean;

  @Emit('oncreateFeedButtonClick')
  async oncreateFeedButtonClick() {
    const response = await this.createFeed();
    return response;
  }

  generateName(prefix = 'DataSource') {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hour = String(now.getHours()).padStart(2, '0');
    const minute = String(now.getMinutes()).padStart(2, '0');
    return `${prefix}-${year}-${month}-${day}-${hour}-${minute}`;
  }

  public onDataChange() {
    this.paramsArray =
      this.schemas.filter((schema) => schema.id === this.newDataSourceSchema?.id)[0].params?.split(',') || [];
  }

  public onFeedTypeChange() {
    this.createDataSourceId = '';
    this.loadDataSources();
    this.validate();
  }

  async loadDataSources() {
    this.isLoadingDataSources = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.handlersDatasourcehdlDataSourceHTTPHandlerList(this.orgId, this.feedType, undefined, {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.loadedDataSources = [];
        for (const l of resp.data.items ?? []) {
          this.loadedDataSources.push({
            name: l.name,
            id: l.id,
          });
        }
      } else {
        const e = new Error('Failed to load data sources. Status Code:' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSources = false;
    }
  }

  public isCreatingFeed = false;
  public isCreatingDataSource = false;
  public isExistingDataSource = false;
  public loadedDataSources: any = [];
  public createDataSourceId = '';
  public createWalletId = '';
  public createWalletAddress = '';
  public networkId = '';
  public createFeedType = 'transaction';

  public newDataSourceName = this.generateName();
  public newFeedName = this.generateName('Feed');
  public newDataSourceSchema: { id: string; name: string } | null = null;
  public accountId = '';

  public newFrequency: { id: string; name: string; value: string } = {
    id: '',
    name: '',
    value: '',
  };

  public dayOfWeek = '';
  public time = '';
  public schemas: DataSvcSchema[] = [];
  public paramsArray: string[] = [];
  public paramValues: Record<string, string> = {};

  public validateNameLength(name: string) {
    return true; // name.length >= 3;
  }

  public get frequencyList() {
    const options = [
      {
        id: 'nightly',
        name: 'Nightly',
      },
      {
        id: 'weekly',
        name: 'Weekly',
      },
      {
        id: 'monthly',
        name: 'Monthly',
      },
      /*  To be added post cron
      {
        id: 'hour',
        name: 'Hour',
      },
      {
        id: 'day',
        name: 'Day',
      },
      {
        id: 'week',
        name: 'Week',
      },
      {
        id: 'month',
        name: 'Month',
      } */
    ];
    return options;
  }

  public get schemaList() {
    return this.schemas
      .filter((s) => this.allowSchemaBeta || !s.isSchemaBeta)
      .map((s) => ({ name: s.isSchemaBeta ? `${s.name} (Beta)` : s.name, id: s.id }));
  }

  public get allowSchemaBeta() {
    return this.checkFeatureFlag('schema-beta', this.$store.getters.features);
  }

  public validate() {
    // if (
    //   this.validateNameLength(this.newFeedName) &&
    //   this.validateNameLength(this.newDataSourceName) &&
    //   this.newDataSourceSchema &&
    //   this.cron
    // ) {
    if (
      (this.validateNameLength(this.newFeedName) &&
        this.validateNameLength(this.newDataSourceName) &&
        this.createDataSourceId &&
        (((this.feedType === 'transaction' || this.feedType === 'addressed-abbs-transaction') && this.createWalletId) ||
          (this.feedType === 'abbs-transaction' && this.createWalletId && this.createWalletAddress))) ||
      this.feedType === 'import-svc-transaction'
      // this.newFrequency.id &&
      // ((this.newFrequency.id === 'weekly' && this.dayOfWeek) || this.newFrequency.id !== 'weekly') &&
      // this.time
    ) {
      this.isValidInput = true;
    } else {
      this.isValidInput = false;
    }
  }

  async mounted() {
    const loadDataCalls = [/* this.loadSchemas(), */ this.loadDataSources()];
    try {
      await Promise.all(loadDataCalls);
      if (this.dataSourceId) {
        this.dataSource = this.loadedDataSources.filter(
          (dataSource: { id: string }) => dataSource.id === this.dataSourceId
        )[0];
        this.newDataSourceName = this.dataSource?.name ?? '';
      }
      if (this.schemaId) {
        this.newDataSourceSchema = this.schemas.filter((schema) => schema.id === this.schemaId)[0];
      }
    } catch (err) {
      console.log(err);
    }
  }

  public async createFeed() {
    this.validate();
    if (this.isValidInput) {
      this.isCreatingFeed = true;
      const userObj = this.$store.state.user;
      const payload = {
        name: this.newFeedName,
        // frequency: getCron(this.newFrequency.id, this.time, this.dayOfWeek),
        // orgId: this.orgId,
        // status: this.status ? 'Enabled' : 'Disabled',
        // user: { name: userObj?.displayName ?? '', id: userObj?.id ?? '' },
        datasourceId: this.createDataSourceId,
        type: this.feedType,
        walletId: this.createWalletId,
        walletAddresses: [this.createWalletAddress],
        networkId: this.networkId,
      } as DataCoreSvcFeedsCreateFeedUnion;
      try {
        const ds = DataApiManager.getInstance();
        const res = await ds.handlersFeedhdlFeedHTTPHandlerCreate(
          this.orgId,
          { data: payload },
          { withCredentials: true }
        );
        if (res.status === 200) {
          this.showSuccessSnackbar('Feed Created Successfully');
          this.$emit('onCreated');
        } else {
          this.showErrorSnackbar('Error creating Feed');
        }
        return res;
      } catch (e) {
        console.log('Error creating Feed: ', e);
      } finally {
        this.isCreatingFeed = false;
      }
    }
  }
}
