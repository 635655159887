







































































































































import axios from 'axios';
import { DataSvcSchema } from 'generated/data-svc/api';
import Component from 'vue-class-component';

import DataApiManager from '@/api/dataApiManager';
import { Contact, ReconciliationStatus, Transaction } from '@/api-svc-types';
import { BaseVue } from '@/BaseVue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiDatePicker from '@/components/ui/UiDatePicker.vue';
import UiDropdown from '@/components/ui/UiDropdown.vue';
import UiFormLabel from '@/components/ui/UiFormLabel.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiRadioGroup from '@/components/ui/UiRadioGroup.vue';
import UiSelect from '@/components/ui/UiSelect.vue';
import UiTextEdit from '@/components/ui/UiTextEdit.vue';
import UiTooltip from '@/components/ui/UiTooltip.vue';
import { RegisterQuery } from '@/queries/registerQuery';
import { isDefined } from '@/utils/guards';

import { baConfig } from '../../../config';
import { getDataSourceSchemaNameByDataSourceId } from './DataImportUtils';
import FeedCreate from './FeedCreate.vue';

@Component({
  components: {
    UiButton,
    UiDropdown,
    UiPagination,
    UiDataTable,
    UiTooltip,
    UiFormLabel,
    UiLoading,
    UiSelect,
    UiRadioGroup,
    UiTextEdit,
    UiDatePicker,
    FeedCreate,
  },
})
export default class FeedExecutions extends BaseVue {
  declare register?: any;

  public isCreatingFeed = false;

  public pageLimit = '10';
  public pageToken = 1;
  public isLoadingFeeds = false;
  public isLoadingDataSource = false;
  public feeds: any = [];
  public feedExecutions: any = [];
  public dataSources: any = [];
  public isLoadingSchemas = false;
  public isLoadingFeedExecutions = false;
  public schemas: DataSvcSchema[] = [];
  public reportsLength = 0;

  async mounted() {
    await this.loadRequiredData();
  }

  getFeedNameById(feedId: string) {
    const feedItem = this.feeds.filter((feed: any) => feed.FeedId === feedId)[0];
    return feedItem ? feedItem.Name : feedId;
  }

  async onDataSourceArgChanged() {
    // Reset page token and keys before loading another data source
    this.pageToken = 1;
    await this.loadFeedExecutions();
  }

  async loadRequiredData() {
    const loadDataSourceAndSchema = [this.loadSchemas(), this.loadDataSources(), this.loadFeeds()];
    await Promise.all(loadDataSourceAndSchema);
    await this.loadFeedExecutions();
  }

  viewReports(feedExecutionId: string): void {
    this.$router.push(`/data/feeds/execution/${feedExecutionId}`);
  }

  async loadDataSources() {
    this.isLoadingDataSource = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.listDataSources(this.orgId, { withCredentials: true });
      if (resp.status === 200) {
        this.dataSources = resp.data.items;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingDataSource = false;
    }
  }

  getDataSourceName(dataSourceId: string): string {
    if (this.dataSources.length) {
      const item = this.dataSources.filter((item: { id: string }) => item.id === dataSourceId)[0];
      return item ? item?.name : dataSourceId;
    }
    return dataSourceId;
  }

  getDataSourceSchemaNameByDataSourceId(dataSourceId: string): string {
    return getDataSourceSchemaNameByDataSourceId(dataSourceId, this.dataSources, this.schemas);
  }

  async loadSchemas() {
    this.isLoadingSchemas = true;
    try {
      const ds = DataApiManager.getInstance();

      const [respSchemas, respCustomSchemas] = await Promise.all([
        ds.listSchemas(this.orgId, { withCredentials: true }),
        ds.listCustomSchemas(this.orgId, { withCredentials: true }),
      ]);
      if (respSchemas.status === 200 && respCustomSchemas.status === 200) {
        const allSchemas = [...respSchemas.data.items, ...respCustomSchemas.data.items];
        // Only show enabled styles
        this.schemas = allSchemas.filter((item) => item.status === 'Enabled');
      }
    } catch (e) {
      console.log('Problem loading schemas', e);
      this.showErrorSnackbar('Problem loading schemas');
    } finally {
      this.isLoadingSchemas = false;
    }
  }

  async loadFeeds() {
    this.isLoadingFeeds = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.getFeeds(this.orgId, undefined, undefined, { withCredentials: true });
      if (resp.status === 200) {
        this.feeds = resp.data.items;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        this.showErrorSnackbar((e as Error).message);
      }
    } finally {
      this.isLoadingFeeds = false;
    }
  }

  async loadFeedExecutions() {
    this.isLoadingFeedExecutions = true;
    try {
      const ds = DataApiManager.getInstance();
      const resp = await ds.getFeedExecutions(this.orgId, this.pageToken, parseInt(this.pageLimit, 10), {
        withCredentials: true,
      });
      if (resp.status === 200) {
        this.feedExecutions = resp.data.items.map((item) => {
          return {
            ...item,
            feedId: item.FeedId,
            feedExecutionId: item.FeedExecutionId,
            dataSourceId: item.DataSourceId,
            startTime: item.StartTime,
            endTime: item.EndTime,
            executedBy: item.ExecutedBy,
            status: item.Status,
          };
        });
        this.reportsLength = resp.data.dataLength;
      } else {
        const e = new Error('Bad response: ' + resp.status);
        console.log((e as Error).message);
        this.showErrorSnackbar('Something went wrong, Please try again later.');
      }
    } catch (err) {
      console.log((err as Error).message);
      this.showErrorSnackbar('Something went wrong, Please try again later.');
    } finally {
      this.isLoadingFeedExecutions = false;
    }
  }

  readonly headers = [
    {
      id: 'icon',
      label: '',
      defaultVisibility: true,
    },
    {
      id: 'feedExecutionId',
      label: 'Feed Execution Id',
      defaultVisibility: true,
    },
    {
      id: 'feedId',
      label: 'Feed Id',
      defaultVisibility: false,
    },
    {
      id: 'name',
      label: 'Feed Name',
      defaultVisibility: true,
    },
    {
      id: 'dataSourceId',
      label: 'Data Source Id',
      defaultVisibility: false,
    },
    {
      id: 'dataSourceName',
      label: 'Data Source Name',
      defaultVisibility: true,
    },
    {
      id: 'dataType',
      label: 'File Type',
      defaultVisibility: true,
    },
    {
      id: 'executedBy',
      label: 'Executed By',
      defaultVisibility: true,
    },
    {
      id: 'startTime',
      label: 'Start Time',
      defaultVisibility: true,
    },
    {
      id: 'endTime',
      label: 'End Time',
      defaultVisibility: true,
    },
    {
      id: 'status',
      label: 'Status',
      defaultVisibility: true,
    },
    {
      id: 'actions',
      label: 'Actions',
      defaultVisibility: true,
    },
  ];

  public allSelected = false;
  public isLoading = 0;
  public showFilters = false;
  public skipQuery = true;

  public endDate = new Date().toISOString().substring(0, 10);

  public vars = {
    filter: {
      categorizationFilter: 'All',
      reconciliationFilter: 'Unreconciled',
      ignoreFilter: 'Unignored',
      walletId: 'All',
      searchTokens: undefined as string[] | undefined,
      errored: undefined as boolean | undefined,
      pivotDate: new Date().toISOString().substring(0, 10),
    },
    limit: '10',
    paginationToken: undefined as string | undefined,
  };

  public get displayRegister() {
    console.log(this.register);
    return this.isLoading ? [] : (this.register?.items ?? []).filter(isDefined);
  }

  public async loadRegister() {
    if (this.$store.state.currentOrg && this.walletId !== 'select') {
      this.isLoading = 1;
      let currencyId;
      let hasError = false;
      if (this.ticker && this.ticker !== null) {
        const assetsApiUrl = `${baConfig.addressSvcUrl}/symbols/${this.ticker}`;
        const resp = await axios.get(assetsApiUrl);
        if (resp === undefined || resp.status !== 200) {
          hasError = true;
          this.showErrorSnackbar(`Invalid ticker ${this.ticker}`);
        } else {
          currencyId = `COIN.${resp.data.coinId}`;
        }
      }
      if (!hasError) {
        const res = await this.$apollo.query({
          query: RegisterQuery,
          variables: {
            orgId: this.$store.state.currentOrg.id,
            filter: {
              walletId: this.walletId,
              endDate: this.endDate,
              currencyId,
            },
          },
        });
        this.register = res.data.register;
      }

      this.isLoading = 0;
    }
  }

  public showMenuOn: Transaction | null = null;
  public expandedTxn: Transaction | null = null;
  public showCreateManualTxnModal = false;
  public ReconciliationStatus = ReconciliationStatus;
  public txnToDelete: Transaction | null = null;
  public deleteDialog = false;
  public isSoftLoading = 0;
  public walletId = 'select';
  public ticker: string | null = null;

  public get categories() {
    return this.$store.getters['categories/ENABLE_CATEGORIES'];
  }

  public get contacts(): Contact[] {
    return this.$store.getters['contacts/ENABLED_CONTACTS'];
  }

  public async refresh() {
    await Promise.all([this.$apollo.queries.register.refetch()]);

    this.$store.dispatch('categories/getCategories', this.$store.state.currentOrg.id);
    this.$store.dispatch('contacts/getContacts', this.$store.state.currentOrg.id);
    this.$store.dispatch('wallets/getWallets', this.$store.state.currentOrg.id);
  }

  toRounded(valStr: string, decimals: number) {
    const fixedString = Number(valStr).toFixed(decimals);
    return Number(fixedString);
  }
}
