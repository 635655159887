var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-600 tw-font-sans",staticStyle:{"font-size":"16px"}},[_vm._m(0),_c('div',[_c('div',{staticClass:"tw-w-full tw-border-b tw-border-gray-300",staticStyle:{"background":"#fafafa"}},[_c('div',{staticClass:"tw-pl-4 sm:tw-pl-6 lg:tw-pl-8 tw-text-gray-800 tw-flex tw-items-center tw-pt-1 tw-mb-1"},[_c('div',{staticClass:"tw-flex tw-items-center"},[_c('fa',{staticClass:"tw-h-4 tw-w-5 tw-py-0.5 tw-mr-4 tw-text-neutral-500 tw-text-primary-300 tw-cursor-pointer",attrs:{"icon":"fa-solid fa-arrow-rotate-right"},on:{"click":_vm.loadRequiredData}})],1),_c('div',{staticClass:"tw-mr-3 tw-flex tw-items-center"},[_c('span',{staticClass:"tw-whitespace-nowrap tw-text-xs tw-text-neutral-300 tw-mr-1"},[_vm._v("Page size ")]),_c('ui-select',{staticClass:"tw-mr-100",attrs:{"values":['10', '20', '50', '100']},on:{"input":_vm.onDataSourceArgChanged},model:{value:(_vm.pageLimit),callback:function ($$v) {_vm.pageLimit=$$v},expression:"pageLimit"}})],1)])]),_c('ui-data-table',{attrs:{"headers":_vm.headers,"isLoading":!!(_vm.isLoadingDataSource || _vm.isLoadingFeeds || _vm.isLoadingSchemas || _vm.isLoadingFeedExecutions),"items":_vm.feedExecutions,"noDataMessage":"There are no feeds executions to display.","hideable-columns":"","resizeable-columns":""},scopedSlots:_vm._u([{key:"td-icon",fn:function(ref){return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_c('fa',{staticClass:"tw-p-2 tw-ml-1 tw-h-4 tw-text-neutral-500 hover:tw-bg-gray-200 tw-rounded-full",attrs:{"icon":"fa-solid fa-arrow-circle-right"}})],1)])]}},{key:"td-feedExecutionId",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(feed.feedExecutionId)+" ")])])]}},{key:"td-feedId",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(feed.feedId)+" ")])])]}},{key:"td-name",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(_vm.getFeedNameById(feed.feedId))+" ")])])]}},{key:"td-dataSourceId",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(feed.dataSourceId)+" ")])])]}},{key:"td-dataSourceName",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(_vm.getDataSourceName(feed.dataSourceId))+" ")])])]}},{key:"td-dataType",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(_vm.getDataSourceSchemaNameByDataSourceId(feed.dataSourceId))+" ")])])]}},{key:"td-status",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(feed.status)+" ")])])]}},{key:"td-executedBy",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(JSON.parse(feed.executedBy).name)+" ")])])]}},{key:"td-startTime",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(new Date(feed.startTime).toLocaleString())+" ")])])]}},{key:"td-endTime",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('div',{staticClass:"tw-whitespace-nowrap tw-flex tw-justify-between tw-items-center"},[_vm._v(" "+_vm._s(feed.endTime ? new Date(feed.endTime).toLocaleString() : '-')+" ")])])]}},{key:"td-actions",fn:function(ref){
var feed = ref.item;
return [_c('td',{},[_c('ui-button',{nativeOn:{"click":function($event){$event.stopPropagation();return _vm.viewReports(feed.feedExecutionId)}}},[_vm._v("View Report")])],1)]}}])}),(_vm.feeds.length !== 0)?_c('ui-pagination',{attrs:{"total":_vm.reportsLength,"itemsPerPage":Number(_vm.pageLimit)},on:{"input":_vm.loadFeedExecutions},model:{value:(_vm.pageToken),callback:function ($$v) {_vm.pageToken=$$v},expression:"pageToken"}}):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-text-gray-600 tw-font-sans",staticStyle:{"font-size":"16px"}},[_c('div',{staticClass:"tw-border-b tw-border-gray-300"},[_c('div',{staticClass:"tw-flex tw-items-center tw-justify-between tw-px-6 tw-py-5"},[_c('h3',{staticClass:"tw-text-2xl tw-capitalize tw-font-medium"},[_vm._v("Feed Execution Reports")])])])])}]

export { render, staticRenderFns }